import request from '@/utils/request';

// 分类目录
export function category(data) {
  return request({
    url: '/category',
    method: 'post',
    data
  });
}

// 商品
export function commodities(data) {
  return request({
    url: '/commodities',
    method: 'post',
    data
  });
}
