<template>
  <div class="details">
    <!-- 导航栏 -->
    <van-nav-bar left-text="返回" right-text="按钮" left-arrow @click-left="onClickLeft" @click-right="onClickRight">
      <template #right>
        <van-icon name="cart-o" size="20" />
      </template>
    </van-nav-bar>

    <!-- 轮播图 -->
    <div class="banner">
      <van-swipe :autoplay="3000" lazy-render>
        <van-swipe-item v-for="item in img" :key="item.id">
          <van-image width="375" height="375" fit="cover" :src="item.url">
            <template v-slot:loading>
              <van-loading vertical type="spinner" size="20" />
            </template>
          </van-image>
        </van-swipe-item>
      </van-swipe>
    </div>

    <!-- 标题 -->
    <div class="title">
      <p>
        <span>¥{{ list.price }}</span>
        <span>月销量{{ list.purchaseNums }}件</span>
      </p>
      <b>
        {{ list.name }}
      </b>
    </div>

    <!-- 商品导航 -->
    <van-goods-action>
      <van-goods-action-icon icon="chat-o" text="客服" color="#ee0a24" />
      <van-goods-action-icon icon="cart-o" text="购物车" badge="5" to="/cart" />
      <van-goods-action-icon icon="star" text="已收藏" color="#ff5000" />
      <van-goods-action-button type="warning" text="加入购物车" @click="addTocart" />
      <van-goods-action-button type="danger" text="立即购买" @click="buynow" />
    </van-goods-action>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { commodities } from '@/api/common';
export default {
  name: 'Details',
  data() {
    return {
      list: [], //详情页数据
      img: [], //详情页图片
      cartlist: [] //本地存储购物车数据
    };
  },
  created() {
    // 操作父组件App数据 子组件修改父组件 隐藏tabbar
    this.$parent.showMenu = false;
    const addcartlist = this.$store.state.addcartlist;
    this.cartlist = addcartlist;

    // 读取本地存储购物车信息
    // let addcartlist = localStorage.getItem('Cartlist'); //[],[{}],null,xxx
    // try {
    //     addcartlist = JSON.parse(addcartlist) || []
    // } catch (error) {
    //     addcartlist = []
    // }
    // this.cartlist = addcartlist;

    // 获取Home路由跳转传过来的参数id
    this.$parent.showMenu = false;
    // 请求单个商品
    this.getProductInfo(this.$route.query.id);
  },
  mounted() {
    this.$parent.showMenu = false;
  },
  destroyed() {
    // 销毁阶段 显示tabbar
    this.$parent.showMenu = true;
  },
  // beforeRouteLeave(to, from, next) {
  //     // 在导航离开渲染该组件的对应路由时调用
  //     // 与 `beforeRouteUpdate` 一样，它可以访问组件实例 `this`
  //     this.$parent.showMenu = true;
  //     next()
  // },
  activated() {
    this.$parent.showMenu = false;
  },
  deactivated() {
    this.$parent.showMenu = true;
  },
  methods: {
    async getProductInfo(id) {
      try {
        const {
          data: { result }
        } = await commodities({ id });
        this.list = result[0];
        this.img = result[0].specPics.split(',').map((item) => ({ url: item, id: uuidv4() }));
        console.log(this.img, 'this.img');
      } catch (error) {}
    },
    onClickLeft() {
      this.$router.back();
    },
    onClickRight() {
      this.$router.push({
        path: '/cart'
      });
    },

    // 添加到购物车
    addTocart() {
      const { defaultPic, id, mktPrice, name, price, purchaseNums, selLineCd, sellsStatus } = this.list;
      const current = this.cartlist.find((item) => item.id === id);
      if (current) {
        current.sellsStatus = current.sellsStatus + 1;
        this.$toast.success('添加成功');
      } else {
        const newgood = {
          defaultPic,
          id,
          mktPrice,
          name,
          price,
          purchaseNums,
          selLineCd,
          sellsStatus
        };
        this.cartlist.unshift(newgood);
      }

      // 重新写入本地存储
      localStorage.setItem('Cartlist', JSON.stringify(this.cartlist));
    },
    buynow() {
      this.addTocart();
      this.$router.push({
        path: '/cart'
      });
    }
  }
};
</script>

<style scoped lang="scss">
.details {
  .title {
    padding: 10px;

    p {
      span {
        &:nth-of-type(1) {
          margin-right: 5px;
          font-size: 18px;
          font-weight: 600;
          color: #7f0019;
        }

        &:nth-of-type(2) {
          float: right;
          font-size: 14px;
          color: #999;
        }
      }

      del {
        font-size: 14px;
        color: #999;
      }
    }

    b {
      font-size: 20px;
      font-weight: 600;
    }
  }
}
</style>
